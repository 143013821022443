  <template>

    <Row cmpt="LabelValueRow" v-if="isReady">
      <Column v-if="labelWidth > 0" :width="labelWidth">
        <Label :fontSize="labelFontSize" :spacer="spacer">
          <slot name="label"></slot>
        </Label>
      </Column>
      <Column :width="15 - labelWidth">
        <Value :fontSize="valueFontSize" :spacer="spacer">
          <slot name="value"></slot>
        </Value>
        <Value :fontSize="'xsmall'">
          <slot name="hint"></slot>
        </Value>
      </Column>
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Label from "@/portals/shared/library/label/Label.vue";
import Value from "@/portals/shared/library/value/Value.vue";

export default {
  name: 'portals-shared-library-labelvalue-labelvalue',
  components: {
    Label,
    Value,
    Row,
    Column,
  },
  props: {
    align: { type: String, default: "left" },
    labelFontSize: { type: String, default: "medium" },
    valueFontSize: { type: String, default: "medium" },
    labelWidth: { type: Number, default: 4 },
    spacer: { type: Boolean, default: false },
    
  },
  data() {
    return {
      isReady: false,
    }
  },
  
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  mounted: function() {
    this.isReady = true;
  },
  methods: {
    alignment: function() {
      return "rc-text-" + this.align;
    }
  },
}
</script>