<template>

    <Row cmpt="LabelRow">
      <Column>
        <Box>
          
          <Row v-if="spacer">
            <Column :width="15" cmpt="spacer">
              <div class="rc-font-small">
                <div > 
                  <div class="rc-font-small rc-text-label">
                    <slot name="space"></slot>
                  </div>
                </div>
              </div>
            </Column>
          </Row>

          <Row>
            <Column :width="15" :class="'rc-text-label ' + styling()">
              <slot name="label"></slot>
            </Column>
          </Row>
          
          <Row v-if="spacer">
            <Column :width="15" :class="'rc-text-label rc-text-xsmall ' + styling()">
              <slot name="hint"></slot>
            </Column>
          </Row>

        </Box>
      </Column>
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

import Row from "@/portals/shared/library/box/Row.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'portals-shared-library-label-label-row',
  components: {
    Row, Box,
    Column,
  },
  props: {
    align: { type: String, default: "centre" },
    cmpt: { type: String, default: "label" },
    spacer: { type: Boolean, default: false },
    fontSize: { type: String, default: "medium" },
    labelWidth: { type: Number, default: 15 },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  methods: {
    styling: function() {
      var value = "";
      
      if (this.align === "left") {
        value += "rc-text-left";
        
      } else if (this.align === "right") {
        value += "rc-text-right";
        
      } else {
        value += "rc-text-centre";
      }
      
      if (this.fontSize) {
        value += " rc-text-" + this.fontSize;
      }
      
      return value;
    }
  },
}
</script>